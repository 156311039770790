import { TeamDressFragment } from '@amf/shared/types/graphql';
import Shirt, { ShirtType } from '@amf/shared/icons/shirts/Shirt';
import classNames from 'classnames';
import { CSSProperties } from 'react';
import { getTeamAwayDress } from '@amf/shared/utils/team';

interface TeamDressProps {
  dress: TeamDressFragment;
  className?: string;
  shorts?: boolean;
  style?: CSSProperties;
  away?: boolean;
}

function TeamDress({ dress, className, shorts, style, away }: TeamDressProps) {
  const awayDress = getTeamAwayDress(dress);
  return (
    <div className={classNames('TeamDress', className)} style={style}>
      {away ? (
        <Shirt
          type={awayDress.awayDressType as ShirtType}
          primary={`#${awayDress.awayDressColorPrimary}`}
          secondary={`#${awayDress.awayDressColorSecondary}`}
          tercial={`#${awayDress.awayDressColorTercial}`}
          shorts={shorts ? `#${awayDress.awayDressColorShorts}` : ''}
        />
      ) : (
        <Shirt
          type={dress.dressType as ShirtType}
          primary={`#${dress.dressColorPrimary}`}
          secondary={`#${dress.dressColorSecondary}`}
          tercial={`#${dress.dressColorTercial}`}
          shorts={shorts ? `#${dress.dressColorShorts}` : ''}
        />
      )}
    </div>
  );
}
export default TeamDress;
