import ShirtPlain from './ShirtPlain';
import ShirtOneVertical from './ShirtOneVertical';
import ShirtHalfVertical from './ShirtHalfVertical';
import ShirtTwoVertical from './ShirtTwoVertical';
import ShirtChess from './ShirtChess';
import ShirtHalfHorizontal from './ShirtHalfHorizontal';
import ShirtOneRotate from './ShirtOneRotate';
import ShirtHalfRotate from './ShirtHalfRotate';
import ShirtTwoRotate from './ShirtTwoRotate';
import ShirtTwoHorizontal from './ShirtTwoHorizontal';

export interface ShirtProps {
  primary: string;
  secondary: string;
  tercial: string;
  shorts: string;
}

export enum ShirtType {
  PLAIN = 'plain',
  ONE_VERTICAL = 'one-vertical',
  HALF_VERTICAL = 'half-vertical',
  TWO_VERTICAL = 'two-vertical',
  CHESS = 'chess',
  HALF_HORIZONTAL = 'half-horizontal',
  ONE_ROTATE = 'one-rotate',
  HALF_ROTATE = 'half-rotate',
  TWO_ROTATE = 'two-rotate',
  TWO_HORIZONTAL = 'two-horizontal',
}

export interface ShirtWithTypeProps extends ShirtProps {
  type: ShirtType;
}

function Shirt({ type, ...props }: ShirtWithTypeProps) {
  switch (type) {
    case ShirtType.PLAIN:
      return <ShirtPlain {...props} />;
    case ShirtType.ONE_VERTICAL:
      return <ShirtOneVertical {...props} />;
    case ShirtType.HALF_VERTICAL:
      return <ShirtHalfVertical {...props} />;
    case ShirtType.TWO_VERTICAL:
      return <ShirtTwoVertical {...props} />;
    case ShirtType.CHESS:
      return <ShirtChess {...props} />;
    case ShirtType.HALF_HORIZONTAL:
      return <ShirtHalfHorizontal {...props} />;
    case ShirtType.ONE_ROTATE:
      return <ShirtOneRotate {...props} />;
    case ShirtType.HALF_ROTATE:
      return <ShirtHalfRotate {...props} />;
    case ShirtType.TWO_ROTATE:
      return <ShirtTwoRotate {...props} />;
    case ShirtType.TWO_HORIZONTAL:
      return <ShirtTwoHorizontal {...props} />;
  }
}

export default Shirt;
