import { ShirtProps } from './Shirt';

function ShirtHalfVertical({ primary, secondary, shorts }: ShirtProps) {
  return (
    <svg
      id='dress-half-vertical'
      width='256'
      height='256'
      viewBox='0 0 256 256'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask
        id='mask3'
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='256'
        height='256'
      >
        <rect width='256' height='256' fill='#C4C4C4' />
      </mask>
      <g mask='url(#mask3)'>
        <path
          className='dress-shorts'
          d='M69 256L70.7879 204H185.212C185.212 224.307 187 256 187 256H69Z'
          fill={shorts}
        />
        <mask
          id='mask33'
          mask-type='alpha'
          maskUnits='userSpaceOnUse'
          x='38'
          y='22'
          width='180'
          height='197'
        >
          <path
            d='M200.066 39.2683C186.903 28.9196 158.736 22.4073 157.541 22.1373C155.339 21.6357 153.146 23.0147 152.645 25.2174C152.578 25.5139 152.543 25.8172 152.543 26.1213C152.543 31.6435 141.212 38.3928 128 38.3928C114.788 38.3928 103.457 31.6435 103.457 26.1213C103.458 24.8765 102.892 23.6992 101.919 22.9227C100.957 22.1231 99.6764 21.8201 98.4585 22.1047C97.2639 22.3747 69.1051 28.895 55.9175 39.2357C42.2962 49.9855 38.4184 101.788 38.0092 107.653C37.8973 109.32 38.8107 110.888 40.3163 111.613C49.9271 116.063 60.2022 118.91 70.733 120.039V206.103C70.7357 207.616 71.5725 209.003 72.9093 209.711C73.572 210.071 89.6311 218.375 128 218.375C166.369 218.375 182.428 210.071 183.091 209.711C184.427 209.003 185.265 207.616 185.267 206.103V120.039C195.795 118.909 206.067 116.062 215.676 111.613C217.185 110.89 218.101 109.322 217.991 107.653C217.582 101.788 213.704 49.9855 200.066 39.2683Z'
            fill='#989898'
          />
        </mask>
        <g mask='url(#mask33)'>
          <path className='dress-primary' d='M0 0H256V256H0V0Z' fill={primary} />
          <path className='dress-secondary' d='M0 0H128V256H0V0Z' fill={secondary} />
        </g>
        <path
          d='M159.235 22.9717C158.3 22.1879 157.061 21.8692 155.864 22.1047C146.66 23.9711 137.357 25.3121 128 26.1217C118.643 25.3125 109.34 23.9715 100.136 22.1047C98.939 21.8704 97.7004 22.1891 96.7654 22.9717C95.8244 23.7472 95.2787 24.9022 95.2764 26.1213C95.2764 38.3928 112.194 46.5736 128 46.5736C143.806 46.5736 160.724 38.3928 160.724 26.1213C160.722 24.9022 160.176 23.7472 159.235 22.9717Z'
          fill='#747474'
        />
      </g>
    </svg>
  );
}

export default ShirtHalfVertical;
