import { TeamDressFragment } from '@amf/shared/types/graphql';

export type TeamAwayDress = {
  awayDressType: string;
  awayDressColorPrimary: string;
  awayDressColorSecondary: string;
  awayDressColorTercial: string;
  awayDressColorShorts: string;
};

export function getTeamAwayDress(team: TeamDressFragment): TeamAwayDress {
  return {
    awayDressType: team.awayDressType ?? team.dressType,
    awayDressColorPrimary: team.awayDressColorPrimary ?? team.dressColorPrimary,
    awayDressColorSecondary: team.awayDressColorSecondary ?? team.dressColorSecondary,
    awayDressColorTercial: team.awayDressColorTercial ?? team.dressColorTercial,
    awayDressColorShorts: team.awayDressColorShorts ?? team.dressColorShorts,
  };
}
